import React from 'react';
import Subheader from '../../../components/Header/navmap/repairShop';
import sitemap from '../../../data/sitemap';
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo";
import Hero from './Hero';
import ContactCustomers from './ContactCustomers';
import PeaceOfMind from './PeaceOfMind';
// import Motivation from './Motivation';
import Perks from './Perks';
import Clients from './Clients';
import Testimonials from './Testimonials';
import CallToAction from '../../CallToAction';

export default ({ seo, location }) => (
  <Layout overlay page={sitemap.industries}
    subpage={sitemap.repairShop._}
    subheader={Subheader}
    url='_url:repair-shop'>
    <SEO {...seo} location={location}/>
    <Hero/>
    <Clients/>
    <ContactCustomers/>
    <PeaceOfMind/>
    <Perks/>
    <Testimonials/>
    <CallToAction/>
  </Layout>
);