import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import i18n, { locale } from '../../../../locale';
import Typography from '../../../../components/Typography';
import Button from '../../../../components/Button';
import Checkpoint from './Checkpoint';
import template from './template';
import styles from './styles.module.sass';

export default () => locale === 'da' ? (
  <StaticQuery
    query={graphql`query {
      checkbox: imageSharp (fluid: { originalName: { eq: "Checkmark.png" }}) {
        fluid(maxWidth: 108, maxHeight: 108, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }`}
    render={({ checkbox }) => (
      <Grid container justifyContent='center' alignContent='center' alignItems='center'
        className={styles.container}>
        <Grid item xs={12} md={12} lg={6} className={clsx(
            styles.gridItem,
            styles.gridItemLeftEdge
          )}>
          <Typography color='dark' variant='h3' align='left' weight='bold'>
            Bliv Værkstedspartner
          </Typography>
          <Typography align='left' variant='h5' weight='light' color='dark'
            className={styles.boxedDescription}>
            Bliv OBI+ Partner og få eksklusive fordele for dig og dine kunder.
          </Typography>
          <div className={styles.buttonContainer}>
              <Link to={i18n('_url:repair-shop-pricing')}
                style={{ textDecoration: 'none'}}>
                <Button className={styles.button}>
                  Kom i gang
                </Button>
              </Link>
            </div>
        </Grid>
        <Grid item xs={12} md={12} lg={6} className={clsx(
            styles.gridItem,
            styles.gridItemRightEdge
          )}>
          {template.map((t, i) => (
            <Checkpoint key={i}
              checkbox={checkbox}
              text={t}/>
          ))}
        </Grid>
      </Grid>
    )
  }/>
)
: null;