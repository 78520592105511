import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from 'gatsby-image/withIEPolyfill';
import Button from '../../../../components/Button';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        hero: imageSharp (fluid: { originalName: { eq: "VC-Main-Hero.jpg" }}) {
          fluid(maxWidth: 1080, maxHeight: 719, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        logo: imageSharp (fluid: { originalName: { eq: "VC-Logo-Text.png" }}) {
          fluid(maxWidth: 400, maxHeight: 104, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ hero, logo }) => (
      <Grid container justifyContent='space-between'
        className={styles.container}>
        
        <Grid item container
          xs={12} sm={12} md={6} lg={4}
          alignContent='center'
        className={styles.overviewContainer}>
          <Img imgStyle={{ objectFit: 'contain' }}
            objectFit='contain'
            objectPosition='left center'
            className={styles.imageLogo}
            fluid={logo.fluid}
            alt='OBI+ Vehicle care'/>
          <Typography variant='h2'
            color='dark'
            weight='regular'
            className={styles.title}>
            {i18n('Create a link to vehicles and customers')}
          </Typography>
          <Typography variant='h5'
            weight='light'
            className={styles.footnote}>
            {i18n('Stay informed about the condition of each connected vehicle and be closer to every customer.')}
          </Typography>
          <div className={styles.buttonContainer}>
            <Link to={`${i18n('_url:repair-shop-pricing')}`}
              style={{ textDecoration: 'none'}}>
              <Button className={styles.button}>
                {i18n('Take a quick tour')}
              </Button>
            </Link>
          </div>
        </Grid>

        <Grid item className={styles.splashContainer}
          xs={12} sm={12} md={6} lg={8}>
          <Img imgStyle={{ objectFit: 'contain' }}
            objectFit='contain'
            objectPosition='top right'
            className={styles.imageContainer}
            fluid={hero.fluid}
            alt={i18n('Remote vehicle management')}/>
        </Grid>
      </Grid>
    )
  }/>
);